import React, { useEffect, useRef, useState } from 'react';
import './PixelAlbumPage.css';

const PixelAlbumPage = ({ currentTrack }) => {
  const canvasRef = useRef(null);
  const [pixelationLevel, setPixelationLevel] = useState(0); // Default pixelation level
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (currentTrack) {
      const albumCoverUrl = currentTrack.album.images[0].url;
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Necessary for cross-origin images
      img.src = albumCoverUrl;

      img.onload = () => {
        setImageLoaded(true);
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Set canvas size to the image size
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the original image on the canvas
        ctx.drawImage(img, 0, 0, img.width, img.height);

        // Apply the initial pixelation effect
        applyPixelation(ctx, img, pixelationLevel);
      };
    }
  }, [currentTrack, pixelationLevel]);

  useEffect(() => {
    if (currentTrack) {
     setPixelationLevel(0);
    }
  }, [currentTrack]);

  const applyPixelation = (ctx, img, level) => {
    // Create a new canvas to draw the pixelated image
    const pixelatedCanvas = document.createElement('canvas');
    const pixelatedCtx = pixelatedCanvas.getContext('2d');
    //console.log(level, Math.max(2+level, 2+ Math.round(img.width * Math.pow(level / 100, 3))))
    pixelatedCanvas.width = Math.max(2+level, 2+ Math.round(img.width * Math.pow(level / 100, 3)));
    pixelatedCanvas.height = Math.max(2+level, 2+ Math.round(img.height * Math.pow(level / 100, 3)));

    //console.log(pixelatedCanvas.width, pixelatedCanvas.height);

    // Draw the image on the smaller canvas
    pixelatedCtx.drawImage(img, 0, 0, pixelatedCanvas.width, pixelatedCanvas.height);

    // Draw the pixelated image back on the original canvas
    ctx.imageSmoothingEnabled = false;
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear the canvas
    ctx.drawImage(pixelatedCanvas, 0, 0, pixelatedCanvas.width, pixelatedCanvas.height, 0, 0, img.width, img.height);
  };

  const handlePixelationChange = (e) => {
    const newLevel = Number(e.target.value);
    setPixelationLevel(newLevel);
  };

  return (
    <div className="pixel-album-page">
      {currentTrack ? (
        <>
          <canvas ref={canvasRef} className="album-canvas" />
          <div className="slider-container">
            <label htmlFor="pixelation">Depixelization Level</label>
            <input
              type="range"
              id="pixelation"
              name="pixelation"
              min="0"
              max="100"
              value={pixelationLevel}
              onChange={handlePixelationChange}
              className="pixelation-slider"
            />
          </div>
        </>
      ) : (
        <p>No track is currently playing. Please start one from your Spotify app.</p>
      )}
    </div>
  );
};

export default PixelAlbumPage;
