import React from 'react';
import './App.css';

const ProgressBar = ({ text, score, valid }) => {
  const color = `rgb(${255 - (score * 2.0)}, ${score * 2.00}, 0)`;
  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ background: `linear-gradient(to right, ${color} ${score}%, grey ${score}%)` }}>
        <div className="progress-text">{valid ? text : '*****'}</div>
      </div>
    </div>
  );
};

export default ProgressBar;
