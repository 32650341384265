// src/SpotifyAuth.js
import React, { useEffect } from 'react';

const CLIENT_ID = '87b89e2280af457a93bdec8b14db204b'; // Replace with your Spotify client ID
const REDIRECT_URI = window.location.origin + '/';
const AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize';
const RESPONSE_TYPE = 'token';
const SCOPE = 'user-read-playback-state user-modify-playback-state';

const SpotifyAuth = () => {
  useEffect(() => {
    const hash = window.location.hash;
    let token = window.localStorage.getItem('token');

    if (!token && hash) {
      token = hash
        .substring(1)
        .split('&')
        .find(elem => elem.startsWith('access_token'))
        .split('=')[1];

      window.location.hash = '';
      window.localStorage.setItem('token', token);
    }
  }, []);

  return (
    <div>
      <a
        href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}`}
      >
        <button>Login to spotify</button>
      </a>
    </div>
  );
};

export default SpotifyAuth;
