import React, { useState, useEffect, useRef, useCallback } from 'react';
import SpotifyWebApi from 'spotify-web-api-js';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import SpotifyAuth from './SpotifyAuth';
import QuizPage from './QuizPage';
import Summary from './Summary';
import PixelAlbumPage from './PixelAlbumPage';
import { getTokenFromUrl } from './utils/token';

const spotifyApi = new SpotifyWebApi();


const setNewTrack = (response, lastTrack, setCurrentTrack, setStartDate, setPlaylist) => {
    const newTrack = response.item;
    if (response.item.id !== lastTrack?.id) {
        setCurrentTrack(response.item);
        setStartDate(Date.now()-response.progress_ms);
        // Extract and set the playlist ID
        if (response.context && response.context.type === 'playlist') {
            const playlistId = response.context.uri.split(':').pop();
            fetchPlaylistDetails(playlistId, setPlaylist);
        } else {
            setPlaylist(null);
        }
    }
}

const fetchNewTrack = async (currentTrack, setCurrentTrack, setLoading, setStartDate, setPlaylist, setToken) => {
  var response = null;
  try {
    response = await spotifyApi.getMyCurrentPlaybackState();
    if (response && response.is_playing) {
      setNewTrack(response, currentTrack, setCurrentTrack, setStartDate, setPlaylist);
    } else {
      setCurrentTrack(null);
    }
  } catch (error) {
    if (error.status === 402 || error.status === 429) {
      setToken('');
      spotifyApi.setAccessToken(null);
      window.location.hash = ''; // Clear token from URL
      setCurrentTrack(null)
    }
    console.error('Error fetching current playback state:', error);
  }
};

const fetchPlaylistDetails = async (playlistId, setPlaylist) => {
  try {
    const playlistData = await spotifyApi.getPlaylist(playlistId);
    setPlaylist(playlistData);
  } catch (error) {
    console.error('Error fetching playlist details:', error);
    setPlaylist(null);
  }
};

function App() {
  const [token, setToken] = useState('');
  const [userId, setUserId] = useState('');
  const [currentTrack, setCurrentTrack] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(0);
  const [playlist, setPlaylist] = useState(null);
  const [responses, setResponses] = useState([]);

  console.log('Apps rerendered', currentTrack?.name, loading, startDate, playlist, responses);

  useEffect(() => { // start the track at launch
    const token = getTokenFromUrl();
    if (token) {
      setToken(token);
      spotifyApi.setAccessToken(token);
      setLoading(true);
      fetchNewTrack(currentTrack, setCurrentTrack, setLoading, setStartDate, setPlaylist, setToken)
        .then(() => setLoading(false));

      spotifyApi.getMe().then((user) => {
        setUserId(user.id);
      }).catch((error) => {
        console.error('Error fetching Spotify user ID:', error);
      });
    }
  }, []);

  useEffect(() => { // Polling for track changes
    if (token) {
      const pollingInterval = setInterval(() => {
        fetchNewTrack(currentTrack, setCurrentTrack, setLoading, setStartDate, setPlaylist, setToken);
      }, 5*1000);
      return () => clearInterval(pollingInterval);
    }
  }, [currentTrack, token]);

  const handleNextTrack = useCallback(() => {
    setLoading(true);
    spotifyApi.skipToNext().then(() => {
      let attempts = 0;
      const intervalId = setInterval(async () => {
        const trackChanged = await fetchNewTrack(currentTrack, setCurrentTrack, setLoading, setStartDate, setPlaylist, setToken);
        setLoading(false);
        if (trackChanged || attempts >= 1) {
          clearInterval(intervalId);
        } else {
          attempts += 1;
        }
      }, 500);
    }).catch(error => {
      console.error('Error skipping to next track:', error);
    });
  }, [currentTrack]);

  const handleEndOfTrack = useCallback(() => {
    if (!loading) {
        setLoading(true);
        fetchNewTrack(currentTrack, setCurrentTrack, setLoading, setStartDate, setPlaylist, setToken)
            .then(() => setLoading(false));
    }
  }, [currentTrack]);

  const fetchResponses = (playlistId) => {
    fetch(`http://quiz.tiersdelinfini.com/api.php/api/get_responses?user_id=${userId}&playlist_id=${playlistId}`)
      .then(res => res.json())
      .then(data => {
        const parsedData = data.map(response => ({
          ...response,
          time: parseFloat(response.time),  // Ensure time is a float
          response: response.response === "1" // Convert "0" and "1" to boolean
        }));
        setResponses(parsedData);
      })
      .catch(error => console.error('Error fetching responses:', error));
  };

  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <Link to="/" className="nav-link">Quiz</Link>
          <Link to="/summary" className="nav-link" onClick={() => fetchResponses(playlist?.id)}>Summary</Link>
          <Link to="/pixel_album" className="nav-link">Pixel Album</Link> {/* New link here */}
        </nav>
        <Routes>
          <Route path="/" element={
            <QuizPage
              token={token}
              userId={userId} // Pass userId to QuizPage
              currentTrack={currentTrack}
              loading={loading}
              handleNextTrack={handleNextTrack}
              handleEndOfTrack={handleEndOfTrack}
              playlist={playlist}
              startDate={startDate}
            />
          } />
          <Route path="/summary" element={<Summary responses={responses} playlist={playlist} />} />
          <Route path="/pixel_album" element={
            <PixelAlbumPage
              token={token}
              userId={userId} // Pass userId to QuizPage
              currentTrack={currentTrack}
              loading={loading}
              handleNextTrack={handleNextTrack}
              handleEndOfTrack={handleEndOfTrack}
              playlist={playlist}
              startDate={startDate}
            />
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
