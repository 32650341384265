import React from 'react';
import './Summary.css';


function Summary({ responses, playlist }) {
  if (!playlist) {
    return <div>No playlist data available.</div>;
  }

  // Check if playlist.tracks is defined and has items
  if (!playlist.tracks || !playlist.tracks.items) {
    return <div>Loading playlist tracks...</div>;
  }

  const allTracks = playlist.tracks.items.map(item => ({
    id: item.track.id,
    name: item.track.name,
    artists: item.track.artists.map(artist => artist.name).join(', '),
    albumArt: item.track.album.images[0].url // Get the album art URL
  }));

  return (
    <div className="summary">
      <h1>Summary for {playlist.name}</h1>
      <table className="summary-table">
        <thead>
          <tr>
            <th>Album Art</th>
            <th>Track</th>
            <th>Artists</th>
            <th>Response</th>
            <th>Timeline (1st minute)</th>
          </tr>
        </thead>
        <tbody>
          {allTracks.map(track => {
            const trackResponses = responses.filter(response => response.track_id === track.id);
            return (
              <tr key={track.id}>
                <td><img src={track.albumArt} alt="Album Art" className="album-art" /></td>
                <td>
                  <span className="track-name" title={track.name}>
                    {track.name.length > 20 ? track.name.substring(0, 17) + '...' : track.name}
                  </span>
                </td>
                <td>{track.artists}</td>
                <td>
                  <div className="response-icons">
                    {trackResponses.map((response, index) => (
                      <span key={index} className={response.response ? "tick" : "cross"}>
                        {response.response ? "✓" : "✗"}
                      </span>
                    ))}
                  </div>
                </td>
                <td>
                  <div className="timeline">
                    {trackResponses.filter(response => response.response).map((response, index) => {
                      const position = Math.min((response.time / 60) * 100, 100); // restrict to first 1 minute
                      return (
                        <span key={index} className="tick-icon" style={{ left: `${position}%` }}>
                          ✓
                        </span>
                      );
                    })}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Summary;
