import { ratio } from 'fuzzball';


export const cleanTitle = (text) => {
  // Remove text after "-"
  const dashIndex = text.indexOf('-');
  if (dashIndex !== -1) {
    text = text.substring(0, dashIndex).trim();
  }
  // Remove anything in parentheses
  text = text.replace(/\([^)]*\)/g, '');
  // Remove anything in brackets
  text = text.replace(/\[[^\]]*\]/g, '');
  return text;
};

export const extractWords = (text) => {
  // Remove extra spaces
  text = text.replace(/\s+/g, ' ').trim();
  const words = text.split(' ');
  return words;
};

// Function to sanitize the title for display
export const sanitizeTitleForDisplay = (title, artists) => {
  // Transform any artist perfectly matching (case insensitive) to "***"
  artists.forEach(artist => {
    const regex = new RegExp(`${artist}`, 'gi');
    title = title.replace(regex, '***');
  });

  return title.trim();
};

// Function to validate user input against Spotify input
export const validateInput = (userInput, spotifyInput, threshold, cleanSpotifyInput) => {
  if (!userInput || !spotifyInput) return { score: 0, valid: false };

  if (cleanSpotifyInput) {
    spotifyInput = cleanTitle(spotifyInput);
  }
  const userWords = extractWords(userInput);
  const spotifyWords = extractWords(spotifyInput);

  let totalScore = 0;
  let allAboveThreshold = true;

  for (let spotifyWord of spotifyWords) {
    spotifyWord = spotifyWord.replace(/Ø/gi, 'o');
    const bestScore = Math.max(...userWords.map(userWord => {
      if (userWord === spotifyWord) {
        return 100; // Exact match
      }
      return ratio(userWord, spotifyWord);
    }));
    totalScore += bestScore;
    if (bestScore < threshold) {
      allAboveThreshold = false;
    }
  }
  const meanScore = allAboveThreshold ? 100 : totalScore / spotifyWords.length;
  return { score: meanScore, valid: allAboveThreshold };
};


