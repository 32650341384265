import React, { useState, useEffect, useRef } from 'react';
import Timer from './utils/Timer';
import ProgressBar from './ProgressBar';
import SpotifyAuth from './SpotifyAuth';
import { sanitizeTitleForDisplay, validateInput } from './utils/strings';
import './App.css';


const QuizPage = ({ token, userId, currentTrack, loading, handleNextTrack, handleEndOfTrack, playlist, startDate}) => {
  const [userInput, setUserInput] = useState('');
  const [titleScore, setTitleScore] = useState(0);
  const [titleValid, setTitleValid] = useState(false);
  const [artistScores, setArtistScores] = useState([]);
  const [artistValidities, setArtistValidities] = useState([]);
  const [isRevealed, setIsRevealed] = useState(false);
  const [threshold, setThreshold] = useState(80); // Adding state for threshold
  const [preloadedImageUrl, setPreloadedImageUrl] = useState(''); // State to track preloaded image URL
  const [timerRunning, setTimerRunning] = useState(false);

  const inputRef = useRef(null);
  const nextButtonRef = useRef(null);
  const timerRef = useRef(0);

  useEffect(() => {
    const savedThreshold = localStorage.getItem('difficultyThreshold');
    if (savedThreshold) {
      setThreshold(Number(savedThreshold));
    }
  }, []);

  useEffect(() => { // Reset state when track changes
    if (currentTrack) {
      console.log('Track changed:', currentTrack.name);
      const artists = currentTrack.artists.map(artist => artist.name);
      resetState(artists);
      if (inputRef.current) {
        inputRef.current.focus(); // Set focus to input when track starts
      }
      setTimerRunning(true);

      // Preload the album cover image
      const albumCoverUrl = currentTrack?.album?.images[0]?.url;
      if (albumCoverUrl) {
        const img = new Image();
        img.src = albumCoverUrl;
        img.onload = () => {
          setPreloadedImageUrl(albumCoverUrl);
        };
      }
    }
  }, [currentTrack]);

  useEffect(() => { // Validate title
    if (currentTrack && !titleValid) {
      const { score: newTitleScore, valid: newTitleValid } = validateInput(userInput, currentTrack.name, threshold, true);
      setTitleScore(newTitleScore);
      setTitleValid(newTitleValid);
    }
  }, [userInput, threshold]);

  useEffect(() => { // Validate artists
    if (currentTrack && !isRevealed) {
      const artists = currentTrack.artists.map(artist => artist.name);
      const newArtistScores = [...artistScores];
      const newArtistValidities = [...artistValidities];
      let artistScoresChanged = false;

      artists.forEach((artist, index) => {
        if (!newArtistValidities[index]) {
          const { score: artistScore, valid: artistValid } = validateInput(userInput, artist, threshold, false);
          newArtistScores[index] = artistScore;
          newArtistValidities[index] = artistValid;
          artistScoresChanged = true;
        }
      });
      if (artistScoresChanged) {
        setArtistScores(newArtistScores);
        setArtistValidities(newArtistValidities);
      }
    }
  }, [userInput, threshold]);

  useEffect(() => { // If both artist and title are valid, stop timer and record response
    if (!isRevealed && titleValid && artistValidities.every(valid => valid)) {
      setIsRevealed(true);
      console.log('All inputs are valid. Stopping timer.');
      setTimerRunning(false);
      recordResponse(true);
      if (nextButtonRef.current) {
        nextButtonRef.current.focus(); // Set focus to next button when revealed
      }
    }
  }, [titleValid, artistValidities]);

  const resetState = (artists) => {
    setUserInput('');
    setTitleScore(0);
    setTitleValid(false);
    setArtistScores(new Array(artists.length).fill(0));
    setArtistValidities(new Array(artists.length).fill(false));
    setIsRevealed(false);
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleThresholdChange = (e) => {
    const newThreshold = Number(e.target.value);
    setThreshold(newThreshold);
    localStorage.setItem('difficultyThreshold', newThreshold);
  };

  const artistLabel = currentTrack && currentTrack.artists.length > 1 ? 'Artists:' : 'Artist:';

  const reveal = () => {
    setTitleScore(0);
    setTitleValid(true);
    setArtistScores(new Array(artistScores.length).fill(0));
    setArtistValidities(new Array(artistValidities.length).fill(true));
    setIsRevealed(true);
    console.log('Track revealed. Stopping timer.');
    setTimerRunning(false);
    recordResponse(false);
    if (nextButtonRef.current) {
      nextButtonRef.current.focus(); // Set focus to next button when revealed
    }
  };

  const recordResponse = (response) => {
    const newResponse = {
      user_id: userId,
      track_id: currentTrack.id,
      playlist_id: playlist?.id,
      response: response,
      time: timerRef.current
    };

    fetch('http://quiz.tiersdelinfini.com/api.php/api/record_response', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newResponse)
    })
      .then(res => res.json())
      .then((data) => {
        if (data.status === 'success') {
          console.log('Response recorded successfully');
        } else {
          console.error('Failed to record response');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    if (isRevealed && preloadedImageUrl) {
      document.body.classList.add('fullscreen-background');
      document.body.style.backgroundImage = `url(${preloadedImageUrl})`;
    } else {
      document.body.classList.remove('fullscreen-background');
      document.body.style.backgroundImage = 'none';
    }
  }, [isRevealed, preloadedImageUrl]);


  const onTick = (elapsedSeconds) => {
      if (currentTrack && elapsedSeconds >= currentTrack.duration_ms / 1000) { // Check if the song has ended
        console.log('Song ended. Fetching new track...');
        handleEndOfTrack();
      }
      timerRef.current = elapsedSeconds;
  }

  return (
    <div className={`quiz-page ${isRevealed ? 'revealed' : 'not-revealed'}`} >

      {!token ? (
        <SpotifyAuth />
      ) : (
        <>
          {loading ? (
            <div className="loading-spinner"></div>
          ) : (
            <>
              {currentTrack ? (
                <div className="track-card">
                  <input
                    type="text"
                    placeholder="Guess the title or artist..."
                    value={userInput}
                    onChange={handleInputChange}
                    className="user-input"
                    ref={inputRef} // Reference for input element
                  />
                  <div className="track-details">
                    <div className="track-element">
                      <span className="label">{artistLabel}</span>
                      {currentTrack.artists.map((artist, index) => (
                        <ProgressBar key={artist.id} text={artist.name} score={artistScores[index] || 0} valid={artistValidities[index]} />
                      ))}
                    </div>
                    <div className="track-element">
                      <span className="label">Title:</span>
                      <ProgressBar text={sanitizeTitleForDisplay(currentTrack.name, currentTrack.artists.map(artist => artist.name))} score={titleScore || 0} valid={titleValid} />
                    </div>
                    <Timer
                        startDate={startDate}
                        timerRunning={timerRunning}
                        onTick={onTick}
                    />
                    {!isRevealed && (
                      <button className="reveal-button" onClick={reveal}>Reveal</button>
                    )}
                    <button
                      className={`next-button ${isRevealed ? 'revealed' : 'not-revealed'}`}
                      onClick={handleNextTrack}
                      ref={nextButtonRef}
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : (
                <p>No track is currently playing. Start one from your Spotify app. <br /> In case of issue, try clearing token.</p>
              )}
            </>
          )}
        </>
      )}
      {token && !loading && !currentTrack && (
        <button onClick={() => {
          window.localStorage.removeItem('token');
          window.location.reload();
        }}>Clear Token</button>
      )}
      <div className="difficulty-slider-container">
        <label htmlFor="threshold">Text matching difficulty</label>
        <br />
        <input
          type="range"
          id="threshold"
          name="threshold"
          min="60"
          max="100"
          value={threshold}
          onChange={handleThresholdChange}
          className="difficulty-slider"
        />
      </div>
    </div>
  );
};

export default QuizPage;
