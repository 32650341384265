// Timer.js
import React, { useState, useEffect } from 'react';

const Timer = ({ startDate, timerRunning, onTick }) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let intervalId;

    if (timerRunning) {
      intervalId = setInterval(() => {
        const elapsedSeconds = (Date.now() - startDate) / 1000;
        setTimer(elapsedSeconds);
        onTick(elapsedSeconds);
      }, 100); // Update every 100ms
    }

    return () => clearInterval(intervalId);
  }, [startDate, timerRunning, onTick]);

  return (
    <div className="timer">
      Time: {timer.toFixed(1)} seconds
    </div>
  );
};

export default Timer;



